import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTAPaywallV2Payment.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { PaymentMethodItem, PaymentMethods, PaywallClickTypes, WTAPaywallV2Product } from "../../../paywalls.constants";
import { PaymentMethodIcon } from "../../ui/icons";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallV2RadioButton } from "../components/WTAPaywallV2RadioButton";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { CheckoutService } from "../../../services/checkout.service";
import { Container } from "../../../../../symphony";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { PaypalPayment } from "../../checkout/PaypalPayment/PaypalPayment";
import { ApplePay } from "../../checkout/ApplePay/ApplePay";
import { CardPayment } from "../../checkout/CardPayment/CardPayment";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title?: string;
    includeMoneyBack?: boolean;
  };
  sectionOrder: number;
};

const allPaymentMethods: PaymentMethodItem[] = [
  {
    method: PaymentMethods.applePay, title: 'Apple Pay', icon: PaymentMethodIcon.applePay
  },
  {
    method: PaymentMethods.payPal, title: 'PayPal', icon: PaymentMethodIcon.payPal
  },
  {
    method: PaymentMethods.card, title: 'Credit Card', icon: <>{PaymentMethodIcon.visa}{PaymentMethodIcon.master}{PaymentMethodIcon.amex}{PaymentMethodIcon.discover}</>
  }
];

export const WTAPaywallV2Payment: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { selectPaymentMethod, submitCardPayment } = Container.take('paywalls', CheckoutService);
  const { trackClick, trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [selectedProduct, setSelectedProduct] = useState<WTAPaywallV2Product>(null);
  const [isTrial, setIsTrial] = useState<boolean>(false);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });
  const {
    supportsApplePay,
    selectedPaymentMethod,
    selectedProductKey,
    w2aV2Products,
    cardInitialized,
    transactionInProcess,
    scrollToPaymentMethods
  } = useContainerData('paywalls', CheckoutStore, [
    'supportsApplePay',
    'selectedPaymentMethod',
    'selectedProductKey',
    'w2aV2Products',
    'cardInitialized',
    'transactionInProcess',
    'scrollToPaymentMethods'
  ]);

  useEffect(() => {
    isInView && trackSectionView('payment', sectionOrder);
  }, [isInView]);

  useEffect(() => {
    if (supportsApplePay) {
      selectPaymentMethod(PaymentMethods.applePay);
    } else {
      selectPaymentMethod(PaymentMethods.payPal);
    }
  }, [supportsApplePay]);

  useEffect(() => {
    const product = w2aV2Products?.find(p => p.product.key === selectedProductKey);
    setSelectedProduct(product);
    setIsTrial(product?.product.trialDays > 0);
  }, [selectedProductKey]);

  useEffect(() => {
    if (scrollToPaymentMethods && ref.current) {
      const offset = 100;
      const container = document.getElementById('paywalls-container');
      const elementPosition = ref.current.getBoundingClientRect().top + container.scrollTop;
      container.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
    }
  }, [scrollToPaymentMethods]);

  const onCardPaymentClick = () => {
    trackClick(PaywallClickTypes.subscribe, 'card');
    submitCardPayment();
  };

  const onPaymentMethodClick = (method: PaymentMethods) => {
    trackClick(PaywallClickTypes.paymentMethod, method);
    selectPaymentMethod(method);
  };

  const getButtonText = () => {
    if (isTrial) {
      return 'Start Free trial';
    } else {
      return selectedProduct?.meta.priceType === 'full' ?
        `Pay $${selectedProduct?.product.discountedAmount.toFixed(2)}` : 'Subscribe';
    }
  };

  const nextBillingDate = () => {
    if (isTrial) {
      return new Date(new Date().setDate(new Date().getDate() + selectedProduct?.product.trialDays)).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, ' ');
    } else {
      return new Date().toLocaleDateString();
    }
  };

  return selectedProductKey ? <div ref={ref as RefObject<HTMLDivElement>} className="payment-methods">
    {data?.title && <WTAPaywallV2Typography className="payment-methods-title" level="h1" text={data.title} />}
    {allPaymentMethods.map(m => <WTAPayawllV2Card
      key={m.method}
      styles={{ padding: '0' }}
      className={`payment-methods-item ${(m.method === PaymentMethods.applePay && !supportsApplePay) ? 'hidden' : ''}`}
    >
      <div
        onClick={() => { onPaymentMethodClick(m.method); }}
        className="payment-methods-item-header"
      >
        <WTAPaywallV2RadioButton className="payment-methods-item-radio-button" selected={selectedPaymentMethod === m.method} />
        <WTAPaywallV2Typography className="payment-methods-item-title" text={m.title} level="h2" />
        <div className={`payment-methods-item-icon ${m.method}`}>{m.icon}</div>
      </div>
      <div className={`payment-methods-item-body ${selectedPaymentMethod === m.method ? 'visible' : 'hidden'}`}>
        {isTrial && <div className="payment-methods-item-body-trial-info">
          <div className="payment-methods-item-body-trial-row">
            <WTAPaywallV2Typography level="paragraph" text="Free trial:" />
            <WTAPaywallV2Typography level="paragraph" text={`${selectedProduct?.product.trialDays} days`} />
          </div>
          <div className="payment-methods-item-body-trial-row">
            <WTAPaywallV2Typography level="paragraph" text="Next billing date:" />
            <WTAPaywallV2Typography level="paragraph" text={`${nextBillingDate()}`} />
          </div>
          <div className="payment-methods-item-body-trial-row">
            <WTAPaywallV2Typography level="paragraph" text="Total after trial:" />
            <WTAPaywallV2Typography level="paragraph" text={`$${selectedProduct?.product.discountedAmount}`} />
          </div>
        </div>}
        <div className={`payment-methods-item-body-total ${m.method === PaymentMethods.card ? 'bordered' : ''}`}>
          <WTAPaywallV2Typography level="h3" text="Total today:" />
          <WTAPaywallV2Typography level="h3" text={`$${isTrial ? '0.00' : selectedProduct?.product.discountedAmount}`} />
        </div>
        {m.method === PaymentMethods.card && <CardPayment
          styles={{
            container: {
              marginBottom: '0',
              rowGap: '10px',
              gridTemplateAreas: '"card card" "exp cvv" "country country" "zip zip"'
            },
            label: {
              color: '#818181',
              fontSize: '16px',
              fontWeight: '600'
            },
            input: {
              backgroundColor: '#FFFCFB',
              borderRadius: '7px',
              border: '1px solid #A2A2A2',
            }
          }}
          layout="vertical"
        />}
        <div className="payment-methods-item-body-button-container">
          {m.method === PaymentMethods.card && selectedProductKey && <WTAPaywallV2Button
            text={`${getButtonText()}`}
            onClick={onCardPaymentClick}
            disabled={!cardInitialized}
            loading={transactionInProcess}
          />}
          {m.method === PaymentMethods.payPal && <PaypalPayment />}
          {m.method === PaymentMethods.applePay && <ApplePay />}
        </div>
      </div>
    </WTAPayawllV2Card>)}
    {data?.includeMoneyBack && <div className="payment-methods-money-back">
      <img src={'https://blob.sololearn.com/web-assets/money-back-0232.png'} className="payment-methods-money-back-icon" />
      <WTAPaywallV2Typography level="h3" text="100% Money-Back Guarantee" />
      <WTAPaywallV2Typography level="paragraph" text="We're so confident that Sololearn can help you achieve your goals that we'll return your money if you don’t see visible results in 30 days (and can prove that you followed our plan)." />
      <div className="payment-methods-money-back-divider"></div>
      <WTAPaywallV2Typography level="h3" text="Guaranteed Safe Checkout" />
      <div className="payment-methods-money-back-payment-icons">
        {PaymentMethodIcon.applePay}
        {PaymentMethodIcon.payPal}
        {PaymentMethodIcon.visa}
        {PaymentMethodIcon.master}
        {PaymentMethodIcon.amex}
        {PaymentMethodIcon.discover}
        {PaymentMethodIcon.jcb}
        {PaymentMethodIcon.dinersClub}
      </div>
    </div>}
  </div> : null;
};
