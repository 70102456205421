import { TrackingEventNames } from '../../../public/tracking/trackingEventNames';
import { TrackingEventsMappingType } from '../tracking.interface';

export const sololearnNewEventsMapping: TrackingEventsMappingType = {
  [TrackingEventNames.signupCompleted]: {
    eventName: 'signup_completed',
    params: [
      'version',
      'method',
      'source',
      ['source_detail', 'sourceDetail'],
      ['source_landing_page_alias', 'sourceLandingPageAlias'],
      ['code_id', 'codeId'],
      'language',
    ],
  },
  [TrackingEventNames.signinCompleted]: {
    eventName: 'signin_completed',
    params: [
      'version',
      'method',
      'source',
      ['source_detail', 'sourceDetail'],
      ['source_landing_page_alias', 'sourceLandingPageAlias'],
      ['code_id', 'codeId'],
      'language',
    ],
  },
  [TrackingEventNames.signinPageImpression]: {
    eventName: TrackingEventNames.signinPageImpression,
    params: [['signin_page_type', 'signinPageType']],
  },
  [TrackingEventNames.signupPageImpression]: {
    eventName: TrackingEventNames.signupPageImpression,
    params: ['version', ['signup_page_type', 'signupPageType']],
  },
  [TrackingEventNames.discussPageView]: {
    eventName: TrackingEventNames.discussPageView,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
    ],
  },
  [TrackingEventNames.onDiscussPostClick]: {
    eventName: TrackingEventNames.onDiscussPostClick,
    params: [
      ['page_alias', 'pageAlias'],
      'source',
      ['click_post_id', 'clickPostId'],
      ['post_id', 'postId'],
    ],
  },
  [TrackingEventNames.onDiscussTagClick]: {
    eventName: 'discuss_tag_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['tag_alias', 'tagAlias'],
      ['click_post_id', 'clickPostId'],
    ],
  },
  [TrackingEventNames.onDiscussSearchClick]: {
    eventName: TrackingEventNames.onDiscussSearchClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['search_text', 'searchText'],
    ],
  },
  [TrackingEventNames.onDiscussCTAClick]: {
    eventName: TrackingEventNames.onDiscussCTAClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['cta_type', 'ctaType'],
    ],
  },
  [TrackingEventNames.onDiscussPaginationClick]: {
    eventName: TrackingEventNames.onDiscussPaginationClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['cta_type', 'ctaType'],
      ['page_number', 'pageNumber'],
      ['click_page_number', 'clickPageNumber'],
    ],
  },
  [TrackingEventNames.onDiscussHomeSectionClick]: {
    eventName: TrackingEventNames.onDiscussHomeSectionClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['section_name', 'sectionName'],
    ],
  },
  [TrackingEventNames.landingPageTargetedClick]: {
    eventName: 'landing_page_targeted_click',
    params: [
      ['landing_page_alias', 'landingPageAlias'],
      ['source_ad_id', 'sourceAdId'],
      ['source_ad_media', 'sourceAdMedia'],
      'section_type',
      'section_title',
      'click_element_id',
    ],
  },
  [TrackingEventNames.landingPageTargetedImpression]: {
    eventName: 'landing_page_targeted_impression',
    params: [
      ['landing_page_alias', 'landingPageAlias'],
      ['source_ad_id', 'sourceAdId'],
      ['source_ad_media', 'sourceAdMedia'],
      'section_type',
      'section_title',
      'click_element_id',
    ],
  },
  [TrackingEventNames.mobilePromotionLandingPageClick]: {
    eventName: 'mobile_promotion_landing_page_click',
    params: [
      ['landing_page_alias', 'landingPageAlias'],
      ['click_type', 'clickType'],
    ],
  },
  [TrackingEventNames.onboardingClick]: {
    eventName: 'onboarding_click',
    params: [
      'version',
      ['action_type', 'actionType'],
      ['answer_id', 'answerId'],
      ['page_id', 'pageId'],
      ['sub_page_id', 'subPageId'],
      ['sub_page_index', 'subPageIndex'],
      ['flow_id', 'flowId'],
      ['navigation_flow', 'navigationFlow'],
      ['source_type', 'sourceType'],
      ['source_details', 'sourceDetails'],
    ],
  },
  [TrackingEventNames.onboardingImpression]: {
    eventName: 'onboarding_impression',
    params: [
      'version',
      ['page_id', 'pageId'],
      ['sub_page_id', 'subPageId'],
      ['sub_page_index', 'subPageIndex'],
      ['flow_id', 'flowId'],
      ['navigation_flow', 'navigationFlow'],
      ['source_type', 'sourceType'],
      ['source_details', 'sourceDetails'],
    ],
  },
  [TrackingEventNames.onHomeStartLearningCTAClick]: {
    eventName: 'landing_page_click',
    params: [
      'version',
      ['experience_type_id', 'experienceTypeId'],
      ['experience_alias', 'experienceAlias'],
      ['cta_type', 'ctaType'],
      ['click_type', 'clickType'],
      ['section_type', 'sectionType'],
      ['section_title', 'sectionTitle'],
    ],
  },
  [TrackingEventNames.homeHeaderSectionView]: {
    eventName: 'landing_page_impression',
    params: [
      'version',
      ['experience_type_id', 'experienceTypeId'],
      ['experience_alias', 'experienceAlias'],
      ['section_type', 'sectionType'],
      ['section_title', 'sectionTitle'],
      ['url_text', 'urlText'],
    ],
  },
  [TrackingEventNames.subscriptionGroupSettingsImpression]: {
    eventName: 'subscription_group_settings_impression',
    params: ['device_id'],
  },
  [TrackingEventNames.subscriptionGroupSettingsActionClick]: {
    eventName: 'subscription_group_settings_action_click',
    params: ['device_id', ['click_type', 'clickType']],
  },
  [TrackingEventNames.subscriptionGroupBannerClick]: {
    eventName: 'subscription_group_banner_click',
    params: [
      'version',
      ['page_url', 'pageUrl'],
      ['click_type', 'clickType'],
      ['banner_type', 'bannerType'],
    ],
  },
  [TrackingEventNames.subscriptionGroupBannerImpression]: {
    eventName: 'subscription_group_banner_impression',
    params: [
      'version',
      ['page_url', 'pageUrl'],
      ['banner_type', 'bannerType'],
    ],
  },
  [TrackingEventNames.materialGroupComplete]: {
    eventName: 'material_group_complete',
    params: [
      'version',
      ['relation_id', 'relationId'],
      ['experience_type_id', 'experienceTypeId'],
      ['experience_alias', 'experienceAlias'],
      ['source', 'source'],
      ['completion_status', 'completionStatus'],
      ['count_bypassed_children', 'countBypassedChildren'],
    ],
  },
  [TrackingEventNames.compilerLanguagesCatalogImpression]: {
    eventName: 'compiler_languages_catalog_impression',
    params: [['page_alias', 'pageAlias']],
  },
  [TrackingEventNames.compilerLanguagesCatalogClick]: {
    eventName: 'compiler_languages_catalog_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['program_language', 'programLanguage'],
      ['click_type', 'clickType'],
      'section',
    ],
  },
  [TrackingEventNames.onCourseClick]: {
    eventName: TrackingEventNames.onCourseClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['material_id', 'materialId'],
      ['cta_type', 'ctaType'],
      ['experience_alias', 'experienceAlias'],
    ],
  },
  [TrackingEventNames.courseCatalogImpression]: {
    eventName: TrackingEventNames.courseCatalogImpression,
    params: [['page_alias', 'pageAlias']],
  },
  [TrackingEventNames.signupPageWebImpression]: {
    eventName: TrackingEventNames.signupPageWebImpression,
    params: ['version', ['signup_page_type', 'signupPageType']],
  },
  [TrackingEventNames.onDiscussJumpLinkClick]: {
    eventName: TrackingEventNames.onDiscussJumpLinkClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['post_id', 'postId'],
      ['link_click_source', 'linkClickSource'],
      ['hyper_text', 'hyperText'],
      ['hyper_link', 'hyperLink'],
    ],
  },
  [TrackingEventNames.downloadNowBannerClick]: {
    eventName: TrackingEventNames.downloadNowBannerClick,
    params: [
      ['page_alias', 'pageAlias'],
      ['click_type', 'clickType'],
    ],
  },
  [TrackingEventNames.downloadNowBannerImpression]: {
    eventName: TrackingEventNames.downloadNowBannerImpression,
    params: [['page_alias', 'pageAlias']],
  },
  [TrackingEventNames.featureUnavailabilityClick]: {
    eventName: TrackingEventNames.featureUnavailabilityClick,
    params: [['click_type', 'clickType']],
  },
  [TrackingEventNames.featureUnavailabilityImpression]: {
    eventName: TrackingEventNames.featureUnavailabilityImpression,
    params: [],
  },
  [TrackingEventNames.userCodeBitsImpression]: {
    eventName: 'user_code_bits_impression',
    params: [['page_alias', 'pageAlias']],
  },
  [TrackingEventNames.userCodeBitsPostClick]: {
    eventName: 'user_code_bits_post_click',
    params: [['page_alias', 'pageAlias'], ['code_id', 'codeId'], 'source'],
  },
  [TrackingEventNames.userCodeBitsSearchClick]: {
    eventName: 'user_code_bits_search_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['search_text', 'searchText'],
    ],
  },
  [TrackingEventNames.userCodeBitsCTAClick]: {
    eventName: 'user_code_bits_cta_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['cta_type', 'ctaType'],
    ],
  },
  [TrackingEventNames.userCodeBitsPaginationClick]: {
    eventName: 'user_code_bits_pagination_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['cta_type', 'ctaType'],
      ['page_number', 'pageNumber'],
      ['click_page_number', 'clickPageNumber'],
    ],
  },
  [TrackingEventNames.userCodeBitsSectionClick]: {
    eventName: 'user_code_bits_section_click',
    params: [
      ['page_alias', 'pageAlias'],
      ['section_name', 'sectionName'],
      ['language_name', 'languageName'],
    ],
  },
  [TrackingEventNames.solotutorImpression]: {
    eventName: 'solotutor_impression',
    params: ['source', 'referer', 'location'],
  },
  [TrackingEventNames.solotutorSendMessage]: {
    eventName: 'solotutor_send_message',
    params: [['conversation_id', 'conversationId']],
  },
  [TrackingEventNames.solotutorCourseLinkClick]: {
    eventName: 'solotutor_click_link',
    params: [
      ['conversation_id', 'conversationId'],
      ['learning_experience_alias', 'courseAlias'],
    ],
  },
  [TrackingEventNames.kodiePlaygroundClick]: {
    eventName: 'kodie_playground_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      'location',
      ['is_pro', 'isPro'],
      ['user_material_relation_id', 'userMaterialRelationId'],
      ['is_response_available', 'isResponseAvailable'],
      ['event_type', 'eventType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
    ],
  },
  [TrackingEventNames.kodiePlaygroundFeedback]: {
    eventName: 'kodie_playground_feedback',
    params: [
      'version',
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
      'location',
      ['is_pro', 'isPro'],
      ['user_material_relation_id', 'userMaterialRelationId'],
      ['feedback_type', 'feedbackType'],
      ['response_id', 'responseId'],
      ['support_item_type', 'supportItemType'],
    ],
  },
  [TrackingEventNames.kodieLessonClick]: {
    eventName: 'kodie_lesson_click',
    params: [
      'location',
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
      ['is_correct', 'isCorrect'],
      ['is_pro', 'isPro'],
      ['user_material_relation_id', 'userMaterialRelationId'],
      ['event_type', 'eventType'],
    ],
  },
  [TrackingEventNames.kodieLessonFeedback]: {
    eventName: 'kodie_lesson_feedback',
    params: [
      'location',
      ['support_item_type', 'supportItemType'],
      ['feedback_type', 'feedbackType'],
      ['is_pro', 'isPro'],
      ['response_id', 'responseId'],
      ['user_material_relation_id', 'userMaterialRelationId'],
    ],
  },
  [TrackingEventNames.kodieLessonImpression]: {
    eventName: 'kodie_lesson_impression',
    params: [
      'location',
      ['impression_type', 'impressionType'],
      ['is_pro', 'isPro'],
      ['is_correct', 'isCorrect'],
      ['user_material_relation_id', 'userMaterialRelationId'],
    ],
  },
  [TrackingEventNames.dynamicTranslationButtonClick]: {
    eventName: 'dynamic_translation_button_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      'language',
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
    ],
  },
  [TrackingEventNames.dynamicTranslationCTAClick]: {
    eventName: 'dynamic_translation_cta_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
    ],
  },
  [TrackingEventNames.dynamicTranslationTipCTAClick]: {
    eventName: 'dynamic_translation_tip_cta_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
    ],
  },
  [TrackingEventNames.joinWaitlist]: {
    eventName: 'join_waitlist',
    params: [
      ['button_id', 'buttonId'],
      ['button_label', 'buttonLabel'],
      ['section_type', 'sectionType'],
      ['section_title', 'sectionTitle'],
      ['url_text', 'urlText'],
      ['url_query', 'urlQuery'],
      'email',
    ],
  },
  [TrackingEventNames.landingPageImpression]: {
    eventName: 'landing_page_impression',
    params: [
      'version',
      ['experience_type_id', 'experienceTypeId'],
      ['experience_alias', 'experienceAlias'],
      ['section_type', 'sectionType'],
      ['section_title', 'sectionTitle'],
      ['url_text', 'urlText'],
      ['url_query', 'urlQuery'],
    ],
  },
  [TrackingEventNames.landingPageClick]: {
    eventName: 'landing_page_click',
    params: [
      'version',
      ['experience_type_id', 'experienceTypeId'],
      ['experience_alias', 'experienceAlias'],
      ['section_type', 'sectionType'],
      ['section_title', 'sectionTitle'],
      ['click_type', 'clickType'],
      ['button_id', 'buttonId'],
      ['button_label', 'buttonLabel'],
      ['url_text', 'urlText'],
      ['url_query', 'urlQuery'],
    ],
  },
  [TrackingEventNames.aiPlaygroundScreenClick]: {
    eventName: 'ai_playground_screen_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['material_id', 'materialId'],
      ['static_id', 'staticId'],
      ['experience_alias', 'experienceAlias'],
      ['experience_type_id', 'experienceTypeId'],
    ],
  },
  [TrackingEventNames.playgroundScreenClick]: {
    eventName: 'playground_screen_click',
    params: [
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
    ],
  },
  [TrackingEventNames.aiPopupWarning]: {
    eventName: 'ai_popup_warning',
    params: [
      'version',
      ['warning_message', 'warningMessage'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
      ['experience_alias', 'experienceAlias'],
      ['experience_type_id', 'experienceTypeId'],
    ],
  },
  [TrackingEventNames.aiResetChat]: {
    eventName: 'ai_reset_chat',
    params: [
      'version',
      ['click_type', 'clickType'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
      ['experience_alias', 'experienceAlias'],
      ['experience_type_id', 'experienceTypeId'],
    ],
  },
  [TrackingEventNames.aiSettingsUpdated]: {
    eventName: 'ai_settings_updated',
    params: [
      'version',
      ['conversation_id', 'conversationId'],
      ['parameters_settings', 'parametersSettings'],
      ['dynamic_id', 'dynamicId'],
      ['static_id', 'staticId'],
      ['material_id', 'materialId'],
      ['experience_alias', 'experienceAlias'],
      ['experience_type_id', 'experienceTypeId'],
    ],
  },
  [TrackingEventNames.aiPaywallsImpression]: {
    eventName: 'paywalls_impression',
    params: [
      'version',
      'type',
      'source',
      ['subscription_product_list_id', 'subscriptionProductListId'],
      ['page_type', 'pageType'],
      ['app_mode', 'appMode'],
      ['app_version', 'appVersion'],
      ['page_number', 'pageNumber'],
      ['experiment_id', 'experimentId'],
      ['was_pro', 'wasPro']
    ],
  },
  [TrackingEventNames.aiPaywallsClick]: {
    eventName: 'paywalls_click',
    params: [
      'version',
      'type',
      'source',
      ['subscription_product_id', 'subscriptionProductId'],
      ['page_type', 'pageType'],
      ['click_type', 'clickType'],
      ['app_mode', 'appMode'],
      ['app_version', 'appVersion'],
      ['page_number', 'pageNumber'],
      ['experiment_id', 'experimentId'],
      ['was_pro', 'wasPro'],
    ],
  },
  [TrackingEventNames.paywallImpression]: {
    eventName: 'paywalls_impression',
    params: [
      'version',
      'touchpoint',
      ['web_page_type', 'webPageType'],
      ['order_number', 'orderNumber'],
      ['app_version', 'appVersion'],
      ['paywall_key', 'paywallKey'],
      ['resolved_variables', 'resolvedVariables'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.paywallClick]: {
    eventName: 'paywalls_click',
    params: [
      'version',
      'touchpoint',
      'value',
      ['web_page_type', 'webPageType'],
      ['click_type', 'clickType'],
      ['order_number', 'orderNumber'],
      ['app_version', 'appVersion'],
      ['paywall_key', 'paywallKey'],
      ['resolved_variables', 'resolvedVariables'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.paywallSectionView]: {
    eventName: 'paywalls_section_view',
    params: [
      'version',
      'touchpoint',
      'section',
      ['section_order', 'sectionOrder'],
      ['web_page_type', 'webPageType'],
      ['order_number', 'orderNumber'],
      ['app_version', 'appVersion'],
      ['paywall_key', 'paywallKey'],
      ['resolved_variables', 'resolvedVariables'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppOpen]: {
    eventName: 'web_to_app_open',
    params: [
      'version',
      ['utm_params', 'utmParams'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppStart]: {
    eventName: 'web_to_app_start',
    params: [
      'version',
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppPageImpression]: {
    eventName: 'web_to_app_page_impression',
    params: [
      'version',
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppPageComplete]: {
    eventName: 'web_to_app_page_complete',
    params: [
      'version',
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId'],
      ['answer_type_ids', 'answerTypeIds'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppQuizAnswer]: {
    eventName: 'web_to_app_quiz_answer',
    params: [
      'version',
      'action',
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId'],
      ['question_id', 'questionId'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppClick]: {
    eventName: 'web_to_app_click',
    params: [
      'version',
      'action',
      ['clicked_element', 'clickedElement'],
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId'],
      ['question_id', 'questionId'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppCheckpointCompleted]: {
    eventName: 'web_to_app_checkpoint_completed',
    params: [
      'version',
      'action',
      'checkpoint',
      ['utm_params', 'utmParams'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId'],
      ['user_agent', 'userAgent']
    ]
  },
  [TrackingEventNames.webToAppEmailProvide]: {
    eventName: 'web_to_app_email_provide',
    params: [
      'version',
      'email',
      ['utm_params', 'utmParams'],
      ['user_agent', 'userAgent'],
      ['flow_id', 'flowId'],
      ['page_id', 'pageId']
    ]
  },
  [TrackingEventNames.webToAppSubscribe]: {
    eventName: 'web_to_app_subscribe',
    params: [
      'version',
      ['utm_params', 'utmParams'],
      ['user_agent', 'userAgent'],
      ['flow_id', 'flowId'],
      ['product_key', 'productKey']
    ]
  },
  [TrackingEventNames.manageSubscriptionClick]: {
    eventName: 'manage_subscriptions_click',
    params: [
      'version',
      ['user_subscription_status', 'userSubscriptionStatus']
    ]
  },
  [TrackingEventNames.cancellationFlowClick]: {
    eventName: 'cancellation_flow_click',
    params: [
      'version',
      ['page_name', 'pageName'],
      ['click_type', 'clickType'],
      ['user_subscription_status', 'userSubscriptionStatus']
    ]
  }
};
