import React, { FC, useEffect, useState } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPayawllV2Card } from "./WTAPayawllV2Card";
import { WTAPaywallV2Label } from "./WTAPaywallV2Label";
import { WTAPaywallIcons } from "./WTAPaywallV2Icons";
import { WTAPaywallV2Typography } from "./WTAPaywallV2Typography";
import { WTAPaywallV2Product } from "../../../paywalls.constants";
import { WTAPaywallV2RadioButton } from "./WTAPaywallV2RadioButton";

type Props = {
  size: 'small' | 'middle' | 'large';
  product: WTAPaywallV2Product;
  className?: string;
  selected?: boolean;
  onClick?: () => void;
};

export const WTAPaywallV2ProductCard: FC<Props> = ({ className, size, product, selected, onClick = () => { } }) => {
  useStyles(s);
  const [priceType, setPriceType] = useState<'full' | 'perDay'>('full');
  const [mainPrevious, setMainPrevious] = useState<string | null>(null);
  const [mainCurrent, setMainCurrent] = useState<string | null>(null);
  const [secondaryPrevious, setSecondaryPrevious] = useState<string | null>(null);
  const [secondaryCurrent, setSecondaryCurrent] = useState<string | null>(null);
  const [isTrial, setIsTrial] = useState<boolean>(false);

  useEffect(() => {
    if (product) {
      setIsTrial(product.product.trialDays > 0);
      setPriceType(product.meta.priceType || 'full');
      if (product.meta.originalPrice) {
        setMainPrevious(product.meta.priceType === 'full'
          ? product.meta.originalPrice.toFixed(2)
          : (product.meta.originalPrice / (product.product.period * 30)).toFixed(2));
        setSecondaryPrevious(product.meta.priceType === "full"
          ? (product.meta.originalPrice / (product.product.period * 30)).toFixed(2)
          : product.meta.originalPrice.toFixed(2)
        );
      } else {
        setMainPrevious(null);
        setSecondaryPrevious(null);
      }
      setMainCurrent(
        product.meta.priceType === 'full'
          ? product.product.discountedAmount.toFixed(2)
          : (product.product.discountedAmount / (product.product.period * 30)).toFixed(2)
      );
      setSecondaryCurrent(
        product.meta.priceType === 'full'
          ? (product.product.discountedAmount / (product.product.period * 30)).toFixed(2)
          : product.product.discountedAmount.toFixed(2)
      );
    }
  }, [product]);

  return <>
    <WTAPayawllV2Card
      className={`wtapv2-product-card ${className || ''} ${size} ${selected ? 'selected' : ''}`}
      onClick={onClick}
      styles={{
        padding: {
          'large': '24px 20px',
          'middle': '0',
          'small': '16px 12px'
        }[size]
      }}
    >
      {size !== 'middle' && product && product.meta.badge && <WTAPaywallV2Label
        className={`wtapv2-product-card-label ${size}`}
        text={product.meta.badge}
        icon={null}
        theme="purple"
      />}

      {size === 'large' && product && <>
        {product.meta.label && <WTAPaywallV2Label
          text={product.meta.label.text}
          icon={product.meta.label.icon ? React.createElement(WTAPaywallIcons[product.meta.label.icon]) : null}
          theme={product.meta.label.theme}
        />}
        <div className="wtapv2-product-card-primary-prices">
          {mainPrevious && <WTAPaywallV2Typography
            text={`$${mainPrevious}`}
            level="h1"
            className="wtapv2-product-card-primary-prices-previous"
          />}
          <WTAPaywallV2Typography
            text={`$${mainCurrent}`}
            level="h1"
            className="wtapv2-product-card-primary-prices-current"
          />
          {priceType === 'perDay' && <WTAPaywallV2Typography
            text="per day"
            level="h3"
            className="wtapv2-product-card-primary-prices-label"
          />}
        </div>
        <div className="wtapv2-product-card-secondary-prices">
          <WTAPaywallV2Typography
            text={`$${secondaryCurrent}`}
            level="caption"
            className="wtapv2-product-card-secondary-prices-current"
          />
          <WTAPaywallV2Typography
            text={priceType === 'full' ? 'per day' : 'total'}
            level="caption"
            className="wtapv2-product-card-secondary-prices-label"
          />
        </div>

        <WTAPaywallV2Typography
          text={product.meta.billingCycleInfo}
          level="paragraph"
        />
      </>}
      {size === 'small' && product && <>
        <div className="wtapv2-product-card-icon">
          <WTAPaywallIcons.dollarSign />
        </div>
        <div className="wtapv2-product-card-content">
          <WTAPaywallV2Typography
            level="caption"
            text="Price"
          />
          <div className="wtapv2-product-card-secondary-prices">
            {mainPrevious && <WTAPaywallV2Typography
              level="h2"
              text={`$${mainPrevious}`}
              className="wtapv2-product-card-secondary-prices-previous small"
            />}
            <WTAPaywallV2Typography
              level="h2"
              text={`$${mainCurrent}`}
              className="wtapv2-product-card-secondary-prices-current small"
            />
            {priceType === 'perDay' && <WTAPaywallV2Typography
              text="per day"
              level="caption"
              className="wtapv2-product-card-secondary-prices-label small"
            />}
          </div>
          <WTAPaywallV2Typography
            text={product.meta.billingCycleInfo}
            level="paragraph"
          />
        </div>
      </>}
      {size === 'middle' && product && <>
        <div className="wtapv2-product-card-middle">
          <div className={`wtapv2-product-card-middle-top ${selected ? 'selected' : ''}`}>
            {product.meta.label?.icon && <div className="wtapv2-product-card-middle-top-icon">
              {React.createElement(WTAPaywallIcons[product.meta.label.icon])}
            </div>}
            <WTAPaywallV2Typography
              level="h3"
              text={product.meta.label?.text}
              className="wtapv2-product-card-middle-top-text"
            />
          </div>
          {product.meta.badge && <div className="wtapv2-product-card-middle-badge">
            <WTAPaywallV2Label
              text={product.meta.badge}
              icon={null}
              theme="purple"
              className={`wtapv2-product-card-middle-left-badge ${selected ? '' : 'unselected'}`}
            />
          </div>}
          <div className="wtapv2-product-card-middle-left">
            <WTAPaywallV2RadioButton className={`wtapv2-product-card-middle-left-radio`} selected={selected} />
            <div className="wtapv2-product-card-middle-left-content">
              <WTAPaywallV2Typography
                level="h1"
                text={product.meta.name}
              />
              {!isTrial && <div className="wtapv2-product-card-middle-left-prices">
                {secondaryPrevious && <WTAPaywallV2Typography
                  level="paragraph"
                  text={`$${secondaryPrevious}`}
                  className="wtapv2-product-card-secondary-prices-previous middle"
                />}
                <WTAPaywallV2Typography
                  level="paragraph"
                  text={`$${secondaryCurrent}`}
                  className="wtapv2-product-card-secondary-prices-current middle"
                />
              </div>}
            </div>
          </div>
          <div className={`wtapv2-product-card-middle-right ${selected ? 'selected' : ''}`}>
            <WTAPaywallV2Typography
              level="h1"
              text={`$${!isTrial ? mainCurrent : '0.00'}`}
              className={`wtapv2-product-card-middle-right-text ${selected ? 'selected' : ''}`}
            />
            <WTAPaywallV2Typography
              level="caption"
              text={!isTrial ? `per day` : 'for 3 days'}
              className={`wtapv2-product-card-middle-right-text ${selected ? 'selected' : ''}`}
            />
          </div>
        </div>
      </>}
    </WTAPayawllV2Card>
  </>;
};
